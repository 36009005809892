// ExcelPreview.js
import React from 'react';
import { Divider, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ExcelPreview = ({ data, excelPreview, addressesVisible, reorderedAddresses }) => {
  const { t } = useTranslation();

  // Only show if data loaded, addresses not visible yet, etc.
  if (data.length === 0) return null;
  if (addressesVisible && reorderedAddresses.length > 0) return null;
  if (excelPreview.length === 0) return null;

  return (
    <>
      <Divider />
      <h3>
        {t('mappy.excelPreview')}
        <Tooltip title={t('mappy.excelPreviewInfo', { count: excelPreview.length })}>
          <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} />
        </Tooltip>
      </h3>
      <div style={{ overflowX: 'auto', border: '1px solid #e8e8e8' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={excelRowNumberHeaderStyle}>{t('mappy.row')}</th>
              {excelPreview[0] &&
                Object.keys(excelPreview[0])
                  .filter((k) => k !== 'key' && k !== 'rowNumber')
                  .map((colKey) => (
                    <th key={colKey} style={excelHeaderStyle}>
                      {colKey}
                    </th>
                  ))}
            </tr>
          </thead>
          <tbody>
            {excelPreview.map((rowData, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td style={excelRowNumberCellStyle}>{rowData.rowNumber}</td>
                {Object.keys(rowData)
                  .filter((k) => k !== 'key' && k !== 'rowNumber')
                  .map((colKey) => (
                    <td key={colKey} style={excelCellStyle}>
                      {rowData[colKey] ?? ''}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

// --------------------------------------------------------------------------------
// Styles for Excel-like preview
// --------------------------------------------------------------------------------
const excelHeaderStyle = {
  border: '1px solid #d9d9d9',
  backgroundColor: '#f0f0f0',
  padding: '8px',
  textAlign: 'center',
  fontWeight: 'bold',
};
const excelCellStyle = {
  border: '1px solid #d9d9d9',
  padding: '8px',
  textAlign: 'left',
};
const excelRowNumberHeaderStyle = {
  ...excelHeaderStyle,
  width: '50px',
};
const excelRowNumberCellStyle = {
  ...excelCellStyle,
  width: '50px',
};

export default ExcelPreview;
