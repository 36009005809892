// src/hooks/useZones.js
import { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default function useZones() {
  const [zones, setZones] = useState([]);
  const [shortUrls, setShortUrls] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const shortenUrl = async (longUrl) => {
    try {
      const response = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longUrl)}`
      );
      return response.data;
    } catch (error) {
      console.error('Error shortening URL:', error);
      return null;
    }
  };

  const splitAddressesIntoZones = (addresses, chunkSize = 10) => {
    const zonesArr = [];
    for (let i = 0; i < addresses.length; i += chunkSize) {
      zonesArr.push(addresses.slice(i, i + chunkSize));
    }
    return zonesArr;
  };

  // The logic to generate Google Maps links
  const handleSubmit = async (reorderedAddresses, addressValidation) => {
    // Check if all addresses are validated
    const invalidAddresses = reorderedAddresses.filter((item) => {
      const isValid = addressValidation[item.id];
      return isValid === false || isValid === undefined;
    });

    if (invalidAddresses.length > 0) {
      message.warning('Some addresses are invalid or not validated. Please validate all before submitting.');
      return;
    }

    setIsSubmitting(true);

    if (!reorderedAddresses.length) {
      message.warning('No addresses to submit.');
      setIsSubmitting(false);
      return;
    }

    // Merge addresses
    const mergedAddresses = reorderedAddresses.map((item) => item.address);

    // Split addresses into zones
    const zonesSplit = splitAddressesIntoZones(mergedAddresses, 10);
    if (!zonesSplit.length) {
      setIsSubmitting(false);
      return;
    }

    if (zonesSplit.length === 1) {
      const zone = zonesSplit[0];
      const destination = zone[zone.length - 1];
      const waypoints = zone.slice(0, -1).join('|');
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        destination
      )}&waypoints=${encodeURIComponent(waypoints)}`;
      window.open(url, '_blank');
      setIsSubmitting(false);
      return;
    }

    const newZones = [];
    for (const [index, zone] of zonesSplit.entries()) {
      const destination = zone[zone.length - 1];
      const waypoints = zone.slice(0, -1).join('|');
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        destination
      )}&waypoints=${encodeURIComponent(waypoints)}`;

      const shortLink = await shortenUrl(url);
      newZones.push({
        id: uuidv4(),
        zoneNumber: index + 1,
        addresses: zone,
        shortUrl: shortLink || url, // fallback if tinyURL fails
      });
    }

    setZones(newZones);
    message.success('Zones created successfully.');
    setIsSubmitting(false);
  };

  // Reset function for useZones
  const resetZones = () => {
    setZones([]);
    setShortUrls([]);
    setIsSubmitting(false);
  };

  return {
    zones,
    setZones,
    shortUrls,
    setShortUrls,
    isSubmitting,
    handleSubmit,

    // reset function
    resetZones,
  };
}
