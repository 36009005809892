import { Collapse, Divider, Table } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const Zones = ({ zones }) => {
  const { t } = useTranslation();

  if (zones.length === 0) return null;
  
  return (
    <>
      <Divider />
      <h3>{t('mappy.zones')}</h3>
      <Collapse accordion>
        {zones.map((zone) => (
          <Panel header={`${t('mappy.zone')} ${zone.zoneNumber}`} key={zone.id}>
            <Table
              dataSource={zone.addresses.map((addr, i) => ({ key: i, address: addr }))}
              pagination={false}
              bordered
              size="small"
              style={{ marginBottom: '10px' }}
            >
              <Table.Column
                title="#"
                dataIndex="key"
                key="key"
                width={50}
                render={(_t, _r, i) => i + 1}
              />
              <Table.Column title={t('mappy.address')} dataIndex="address" key="address" />
            </Table>
            <div style={{ marginTop: '10px' }}>
              <strong>{t('mappy.zoneUrl')}:</strong>{' '}
              <a href={zone.shortUrl} target="_blank" rel="noopener noreferrer">
                {zone.shortUrl}
              </a>
            </div>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default Zones;
  