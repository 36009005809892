// src/hooks/useReordering.js
import { useState } from 'react';

export default function useReordering() {
  const [reorderedAddresses, setReorderedAddresses] = useState([]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const temp = [...reorderedAddresses];
    const [moved] = temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, moved);
    setReorderedAddresses(temp);
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    const temp = [...reorderedAddresses];
    [temp[index - 1], temp[index]] = [temp[index], temp[index - 1]];
    setReorderedAddresses(temp);
  };

  const handleMoveDown = (index) => {
    if (index === reorderedAddresses.length - 1) return;
    const temp = [...reorderedAddresses];
    [temp[index + 1], temp[index]] = [temp[index], temp[index + 1]];
    setReorderedAddresses(temp);
  };

  const handleRemoveAddress = (index) => {
    const updatedAddresses = reorderedAddresses.filter((_, i) => i !== index);
    setReorderedAddresses(updatedAddresses);
  };

  // Reset function for useReordering
  const resetReordering = () => {
    setReorderedAddresses([]);
  };

  return {
    reorderedAddresses,
    setReorderedAddresses,
    handleOnDragEnd,
    handleMoveUp,
    handleMoveDown,
    handleRemoveAddress,

    // reset function
    resetReordering,
  };
}
