// MappyV1.jsx
import React from 'react';
import MappyBase from './MappyBase';

/**
 * MappyV1:
 *   - autoExtract = false
 *   - Has a single button "Extract & Validate"
 *   - The addresses won't be shown until user clicks that button
 */

export default function MappyV1() {
  return <MappyBase autoExtract={false} />;
}
