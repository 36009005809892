// src/hooks/useFileManagement.js
import { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { message } from 'antd';

export default function useFileManagement() {
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetData = () => {
    setData([]);
    setUploadedFileName('');
    setIsLoading(false);
  };

  const handleFileChange = (file) => {
    resetData();
    setIsLoading(true);
    setUploadedFileName(file.name);
  
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryStr = evt.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  
      // Use the range option to limit rows or filter out empty rows
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
      
      // Filter out rows that have no meaningful data
      const filteredData = sheetData.filter(row => row.some(cell => cell !== '' && cell !== undefined));
  
      setData(filteredData);
      setIsLoading(false);
      message.success('File uploaded successfully.');
    };
    reader.readAsBinaryString(file);
  };

  // Expose a dedicated reset function for consistency
  const resetFileManagement = () => {
    resetData();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return {
    fileInputRef,
    data,
    uploadedFileName,
    setUploadedFileName,
    isLoading,
    handleFileChange,
    resetData,
    resetFileManagement,
  };
}
