import { Table, Button, Tooltip, Spin } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';

const AddressesTable = ({ 
  reorderedAddresses, 
  handleOnDragEnd, 
  handleMoveUp, 
  handleMoveDown, 
  handleRemoveAddress, 
  autoExtract, 
  addressValidation
}) => {
  const { t } = useTranslation();

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="addresses">
        {(provided) => (
          <Table
            {...provided.droppableProps}
            dataSource={reorderedAddresses}
            rowKey="id"
            pagination={false}
            ref={provided.innerRef}
            bordered
          >
            <Table.Column
              title="#"
              key="index"
              render={(_text, _record, idx) => idx + 1}
              width={50}
            />
            <Table.Column title={t('mappy.address')} dataIndex="address" key="address" />
            <Table.Column
              title={t('mappy.validation')}
              key="validation"
              render={(_text, record) => {
                const valid = addressValidation[record.id];
                if (valid === undefined) {
                  return autoExtract ? "❓" : <Spin size="small" />;
                }
                return valid ? (
                  <span style={{ color: 'green' }}>✅ {t('mappy.valid')}</span>
                ) : (
                  <span style={{ color: 'red' }}>❌ {t('mappy.invalid')}</span>
                );
              }}
            />
            <Table.Column
              title={t('mappy.actions')}
              key="actions"
              width={150}
              render={(_text, _rec, index) => (
                <>
                  <Tooltip title={t('mappy.moveUp')}>
                    <Button
                      icon={<ArrowUpOutlined />}
                      disabled={index === 0}
                      onClick={() => handleMoveUp(index)}
                      style={{ marginRight: '5px' }}
                    />
                  </Tooltip>
                  <Tooltip title={t('mappy.moveDown')}>
                    <Button
                      icon={<ArrowDownOutlined />}
                      disabled={index === reorderedAddresses.length - 1}
                      onClick={() => handleMoveDown(index)}
                      style={{ marginRight: '5px' }}
                    />
                  </Tooltip>
                  <Tooltip title={t('mappy.removeAddress')}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveAddress(index)}
                      danger
                    />
                  </Tooltip>
                </>
              )}
            />
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  )
};

export default AddressesTable;
