// src/hooks/useValidation.js
import { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';

export default function useValidation() {
  const [addressValidation, setAddressValidation] = useState({});
  const [isValidating, setIsValidating] = useState(false);

  // Example of how to validate addresses
  const validateAddresses = async (addressesToValidate) => {
    if (!Array.isArray(addressesToValidate)) {
      console.error('validateAddresses expects an array.');
      return;
    }
    setIsValidating(true);
    setAddressValidation({});

    const validationPromises = addressesToValidate.map(async (addressObj) => {
      try {
        // Example endpoint
        const response = await axios.post('/validate_address.php', {
          address: addressObj.address,
        });
        const isValid = response.data.is_valid;
        setAddressValidation((prev) => ({
          ...prev,
          [addressObj.id]: isValid,
        }));
      } catch (error) {
        console.error(`Error validating address "${addressObj.address}":`, error);
        setAddressValidation((prev) => ({
          ...prev,
          [addressObj.id]: false,
        }));
      }
    });

    await Promise.all(validationPromises);
    setIsValidating(false);
    message.success('Address validation completed.');
  };

  // Reset function for useValidation
  const resetValidation = () => {
    setAddressValidation({});
    setIsValidating(false);
  };

  return {
    addressValidation,
    setAddressValidation,
    isValidating,
    validateAddresses,

    // reset function
    resetValidation,
  };
}
