// MappyV2.jsx
import React from 'react';
import MappyBase from './MappyBase';

/**
 * MappyV2:
 *   - autoExtract = true
 *   - As soon as columns are selected, addresses appear automatically
 *   - The user must click "Validate Addresses" to validate them
 */

export default function MappyV2() {
  return <MappyBase autoExtract={true} />;
}
