// FileUpload.js
import React from 'react';
import { Button, Upload, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FileUpload = ({ handleFileUpload, uploadedFileName, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t('mappy.excelFile')}</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Upload
          accept=".xlsx, .xls"
          beforeUpload={(file) => {
            handleFileUpload(file);
            return false; // disable auto upload
          }}
          showUploadList={false}
          aria-label={t('mappy.excelFile')}
        >
          <Button icon={<UploadOutlined />}>{t('mappy.fileUpload')}</Button>
        </Upload>
        
        {isLoading && (
          <div style={{ marginLeft: '10px' }}>
            <Spin />
          </div>
        )}
      </div>

      {uploadedFileName && (
        <div style={{ marginTop: '10px', fontStyle: 'italic', color: '#555' }}>
          <strong>{t('mappy.uploadedFile')}</strong> {uploadedFileName}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
