import { useState, useEffect, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export default function useColumns(initialData, resetReordering, resetZones) {
  const { t } = useTranslation();

  const [headerRow, setHeaderRow] = useState(0);
  const [startRow, setStartRow] = useState(1);

  const [headerRowError, setHeaderRowError] = useState('');
  const [startRowError, setStartRowError] = useState('');

  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const data = useMemo(() => initialData || [], [initialData]);

  // Build columnsOptions from header row
  useEffect(() => {
    if (data.length > 0 && headerRow !== null && headerRow !== undefined) {
      const headerRowIndex = headerRow - 1; // Excel-based => zero-based
      const maxColumns = data.reduce((max, row) => Math.max(max, row.length), 0);
      let headers = [];

      if (headerRowIndex >= 0 && headerRowIndex < data.length) {
        const headerData = data[headerRowIndex];
        headers = Array.from({ length: maxColumns }, (_, index) => {
          const headerValue = headerData[index]?.toString().trim();
          return {
            label: headerValue !== undefined && headerValue !== '' 
              ? headerValue 
              : `Column ${String.fromCharCode(65 + index)}`,
            value: String.fromCharCode(65 + index),
          };
        });
      } else {
        // No valid header row => default column naming
        headers = Array.from({ length: maxColumns }, (_, index) => ({
          label: `Column ${String.fromCharCode(65 + index)}`,
          value: String.fromCharCode(65 + index),
        }));
      }
      setColumnsOptions(headers);
    } else {
      setColumnsOptions([]);
      setSelectedColumns([]);
    }
  }, [data, headerRow]);

  // Extract addresses without sorting
  const addresses = useMemo(() => {
    if (!data.length || !selectedColumns.length) return [];

    const startRowIndex = startRow - 1; // zero-based
    return data.slice(startRowIndex).map((row) => {
      const parts = selectedColumns.map((col) => {
        const colIndex = XLSX.utils.decode_col(col.trim().toUpperCase());
        // Trim each cell value from the selected columns, ensuring no leading/trailing spaces
        return row[colIndex] !== undefined && row[colIndex] !== ''
          ? row[colIndex].toString().trim()  // Trim the cell value here
          : '';
      });

      const addressString = parts.filter(Boolean).join(', ');

      // Create the final address object with trimmed values
      return addressString
        ? { id: uuidv4(), row: row.map(cell => cell.trim()), address: addressString }  // Trim each cell in the row here too
        : null;
    }).filter(Boolean);
  }, [data, selectedColumns, startRow]);

  const handleHeaderRowChange = (value) => {
    setHeaderRow(value);
    resetValidationErrors();

    resetReorderingAndZones();

    if (value !== null && value !== undefined) {
      setStartRow(value + 1);
      message.info(t('mappy.startRowInfo', { startRow: value + 1, headerRow: value }));

      if (value < 0 || value > data.length) {
        setHeaderRowError(t('mappy.headerRowMinValue', { value: data.length }));
      }
    }
  };

  const handleStartRowChange = (value) => {
    if (value >= 1 && value >= headerRow) {
      setStartRow(value);
      resetValidationErrors();

      resetReorderingAndZones();

      if (value < 1) {
        setStartRowError(t('mappy.startRowMinValue'));
      } else if (headerRow !== null && value <= headerRow) {
        setStartRowError(t('mappy.startRowRangeValue', { headerRow: headerRow }));
      } else if (value > data.length) {
        setStartRowError(t('mappy.startRowMaxValue', { value: data.length }));
      }
    }
  };

  const handleSelectedColumnsChange = (value) => {
    setSelectedColumns(value);
  
    resetReorderingAndZones();
  };

  const resetValidationErrors = () => {
    setHeaderRowError('');
    setStartRowError('');
  }

  const resetReorderingAndZones = () => {
    resetReordering();
    resetZones();
  };

  // Reset function for useColumns
  const resetColumns = () => {
    setHeaderRow(0);
    setStartRow(1);
    resetValidationErrors();
    setColumnsOptions([]);
    setSelectedColumns([]);
  };

  return {
    // states
    headerRow,
    setHeaderRow,
    headerRowError,
    setHeaderRowError,
    startRow,
    setStartRow,
    startRowError,
    setStartRowError,
    columnsOptions,
    setColumnsOptions,
    selectedColumns,
    setSelectedColumns,
    addresses,

    // handlers
    handleHeaderRowChange,
    handleStartRowChange,
    handleSelectedColumnsChange,

    // reset function
    resetColumns,
  };
}
