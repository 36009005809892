// AddressButtons.js
import React from 'react';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const AddressButtons = ({ 
  autoExtract,
  addressesVisible,
  confirmReset,
  reorderedAddresses,
  validateAddresses,
  isSubmitting,
  isValidating,
  handleSubmit
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '20px' }}>
      {/* V2 => "Validate Addresses" if addresses visible */}
      {autoExtract && addressesVisible && (
        <Button
          type="primary"
          onClick={() => validateAddresses(reorderedAddresses)}
          disabled={reorderedAddresses.length === 0 || isValidating}
        >
          {isValidating ? <Spin size="small" /> : t('mappy.validateAddresses')}
        </Button>
      )}

      {/* Common "Submit" button (only if addresses are visible) */}
      {addressesVisible && (
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ marginLeft: '10px' }}
          disabled={reorderedAddresses.length === 0 || isSubmitting}
          aria-label={t('mappy.submit')}
        >
          {isSubmitting ? <Spin size="small" /> : t('mappy.submit')}
        </Button>
      )}

      {/* Reset for both */}
      <Button 
        onClick={confirmReset} 
        style={{ marginLeft: '10px' }}
        aria-label={t('mappy.reset')}
      >
        {t('mappy.reset')}
      </Button>
    </div>
  );
};

export default AddressButtons;
