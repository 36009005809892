// Settings.js
import React from 'react';
import { Button, Divider, InputNumber, Select, Alert, Tooltip, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Settings = ({
  data,
  headerRow,
  handleHeaderRowChange,
  startRow,
  handleStartRowChange,
  headerRowError,
  startRowError,
  columnsOptions,
  availableColumnsOptions,
  selectedColumns,
  handleSelectedColumnsChange,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder,
  isSortVisible,
  autoExtract,
  onExtractAndValidateV1,
  isValidating
}) => {
  const { t } = useTranslation();

  if (data.length === 0) return null;

  return (
    <>
      <Divider />
      <h3>{t('mappy.settings')}</h3>

      {/* Header Row */}
      <div style={{ marginTop: '20px' }}>
        <label style={{ marginRight: '10px' }}>{t('mappy.headerRow')}:</label>
        <InputNumber
          value={headerRow}
          min={0}
          inputMode="numeric"
          onChange={handleHeaderRowChange}
          style={{ width: '80px' }}
          aria-label={t('mappy.headerRow')}
        />
        <Tooltip title={t('mappy.headerRowHelp')}>
          <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} />
        </Tooltip>
        {headerRowError && (
          <Alert
            message={headerRowError}
            type="error"
            showIcon
            style={{ marginTop: '5px' }}
          />
        )}
      </div>

      {/* Start Row */}
      <div style={{ marginTop: '20px' }}>
        <label style={{ marginRight: '10px' }}>{t('mappy.startRow')}:</label>
        <InputNumber
          value={startRow}
          min={1}
          inputMode="numeric"
          onChange={handleStartRowChange}
          style={{ width: '80px' }}
        />
        <Tooltip title={t('mappy.startRowHelp')}>
          <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} />
        </Tooltip>
        {startRowError && (
          <Alert
            message={startRowError}
            type="error"
            showIcon
            style={{ marginTop: '5px' }}
          />
        )}
      </div>

      {/* Columns */}
      {headerRow !== null && (
        <div style={{ marginTop: '20px' }}>
          <label style={{ marginRight: '10px' }}>{t('mappy.selectColumns')}:</label>
          <Select
            mode="multiple"
            value={selectedColumns}
            onChange={handleSelectedColumnsChange}
            style={{ width: '350px' }}
            options={availableColumnsOptions}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            aria-label={t('mappy.selectColumns')}
          />
          {selectedColumns.length === 0 && (
            <Alert
              message={t('mappy.selectColumnsHelp')}
              type="warning"
              showIcon
              style={{ marginTop: '5px' }}
            />
          )}
        </div>
      )}

      {/* Sorting (only visible if user selected columns) */}
      {isSortVisible && (
        <>
          <div style={{ marginTop: '20px' }}>
            <label style={{ marginRight: '10px' }}>{t('mappy.orderBy')}:</label>
            <Select
              value={orderBy}
              onChange={(val) => setOrderBy(val)}
              style={{ width: '200px' }}
              placeholder="Select column"
              options={columnsOptions}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              aria-label={t('mappy.orderBy')}
            />
          </div>

          {orderBy && (
            <div style={{ marginTop: '20px' }}>
              <label style={{ marginRight: '10px' }}>{t('mappy.sortDirection')}:</label>
              <Select
                value={sortOrder}
                onChange={(val) => setSortOrder(val)}
                style={{ width: '200px' }}
              >
                <Option value="asc">{t('mappy.ascending')}</Option>
                <Option value="desc">{t('mappy.descending')}</Option>
              </Select>
            </div>
          )}
        </>
      )}

      {/* For V1 => one-time "Extract & Validate" button */}
      {!autoExtract && (
        <div style={{ marginTop: '20px' }}>
          <Button
            type="primary"
            onClick={onExtractAndValidateV1}
            disabled={
              selectedColumns.length === 0 ||
              headerRowError ||
              startRowError ||
              isValidating
            }
          >
            {isValidating ? <Spin size="small" /> : t('mappy.extractColumns')}
          </Button>
        </div>
      )}
    </>
  );
};

export default Settings;
